import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import User from './User'
import Main from './Main'

export default function App () {
  return (
    <Router>
      <Switch>
        <Route path="/u/:name">
          <User />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router >
  );
}