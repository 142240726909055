// import logo from './logo.svg';
import './App.css';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
// import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

function Users () {
  let { name } = useParams()
  let [rows, setRows] = useState([])
  let [loading, setLoading] = useState(true)
  useEffect(() => {
    axios.post(`https://twitch-vips.herokuapp.com/user`, { user: name })
      .then(res => {
        setLoading(false)
        setRows(res.data)
      })
    document.title = `${name.toUpperCase()} VIPS`
  }, [name])

  const columns = [
    {
      name: "channel",
      label: "Channel",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "followers",
      label: "Followers",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "views",
      label: "Views",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
      }
    }
  ];

  const options = {
    responsive: 'horizontal',
    sortOrder: {
      name: 'channel',
      direction: 'asc'
    },
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    selectableRowsHeader: false,
    selectableRows: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100]
  };

  // const getMuiTheme = () =>
  //   createTheme({
  //     overrides: {
  //       MUIDataTableToolbar: {
  //         root: {
  //           backgroundColor: "#181a1b",
  //           color: "#e8e6e3de"
  //         }
  //       },
  //       MuiTableCell: {
  //         root: {
  //           backgroundColor: "#181a1b",
  //         },
  //       },
  //       MUIDataTableBodyCell: {
  //         root: {
  //           backgroundColor: "#181a1b",
  //           color: "#e8e6e3de",
  //           borderColor: '#393d40'
  //         },
  //       },
  //       MUIDataTablePagination: {
  //         root: {
  //           backgroundColor: "#000",
  //           color: "#fff",
  //         },
  //       },
  //     },
  //   });

  return (
    loading ?
      (
        <div style={{ minHeight: "100vh", maxWidth: "100%", display: "flex", alignItems: "center", justifyContent:"center"/*, backgroundColor: '#181a1b'*/ }}>
          <ClipLoader
            size='60'
            color='#425FF5'
          />
        </div>) :
      (<div style={{ minHeight: "100vh", maxWidth: "100%"/*, backgroundColor: '#181a1b'*/ }}>
        <MUIDataTable
          title={`${name.toUpperCase()} VIPS`}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>)
  );
}

export default Users;
