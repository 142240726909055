import React, { useRef } from "react";
// import "./Main.css";
import { Grid, TextField, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function Main () {
  document.title = 'VIPS SEARCH'
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      style={{ minHeight: "100vh", maxWidth: "100%" }}
      spacing={5}
    >
      <Grid item >
        <Search />
      </Grid>
    </Grid>
  );
}

const Search = () => {
  let textInput = useRef(null);
  const history = useHistory();

  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <TextField
        variant="outlined"
        label="Channel"
        fullWidth
        style={{ marginBottom: "2em" }}
        inputRef={textInput}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            history.push(`/u/${textInput.current.value.toLowerCase()}`)
          }
        }}
      />
      <Button type="submit" size="large" variant="contained" color="primary" onClick={() => { history.push(`/u/${textInput.current.value.toLowerCase()}`) }}>
        Search
      </Button>
    </Grid>
  );
};
